import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { get, set, remove, clear } from './storage.service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { catchError, map } from 'rxjs/operators';
import { AuthService as AuthServices } from 'ionic-appauth';

interface IToken {
  /**
   * Access token.
   */
  accessToken: string;
  user: User;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  token: any;

  constructor(private http: HttpClient, private auth: AuthServices) {}

  public performLogin(data: any): Observable<boolean> {
    let locale = localStorage.getItem('lang') || 'es';
    let data2 = {
      account_id: data.churchAccountID,
      token: localStorage.token,
      given_name: data.firstName,
      last_name: data.lastName,
      name: data.displayName,
      email: data.personalEmail,
      device_token: localStorage.fcmToken || null,
      locale: locale,
    };
    const httpOptions = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'applicsation/json',
        Accept: 'application/json',
      }),
    };
    return this.http.post<any>(`${environment.apiUrl}auth/login`, data2).pipe(
      map((user: any) => {
        if (user.status) {
          localStorage.setItem('name', data.displayName);
          localStorage.setItem('accessToken', user.access_token);
          localStorage.setItem('email', data.personalEmail);
          localStorage.setItem('churchAccountID', data.churchAccountID);
          localStorage.setItem('countryId', user.countries[0]?.id);
          localStorage.setItem('callingId', user.callings[0]?.id);
          localStorage.setItem('callingName', user.callings[0]?.name);
          localStorage.setItem('callingStake', user.stake?.id);
          set('user', data);
          return true;
        } else {
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }
  public addDevice(token: string): Observable<boolean> {
    const user = { token: token };
    return this.http.post<any>(`${environment.apiUrl}devices`, user).pipe(
      map((token: any) => {
        return true;
      }),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }
  public performRecover(emailUser: string): Observable<boolean> {
    const user = { email: emailUser };
    return this.http
      .post(`${environment.apiUrl}auth/password/email`, user)
      .pipe(
        map(() => {
          return true;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
  public performUpdatePass(
    id: number,
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Observable<boolean> {
    const user = {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
    };
    return this.http.put(`${environment.apiUrl}user/${id}/password`, user).pipe(
      map(() => {
        return true;
      }),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }
  public removeAccount(): Observable<boolean> {
    const user = {
      church_account_id: localStorage.churchAccountID,
    };
    return this.http
      .post(`${environment.apiUrl}auth/remove-account`, user)
      .pipe(
        map(() => {
          this.cleanLocalStorage();
          clear();
          return true;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  async logout() {
    this.isLoggedIn = false;
    await this.http.get(environment.apiUrl + 'auth/logout').pipe(
      tap((data) => {
        return true;
      })
    );
    await this.auth.signOut();
    await this.cleanLocalStorage();
    window.location.reload();
    return true;
  }
  async cleanLocalStorage() {
    localStorage.removeItem('inviteuser');
    localStorage.removeItem('token');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('issuedAt');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('fcmToken');
    localStorage.removeItem('type_client');
    localStorage.removeItem('cap_sec_token_response');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('churchAccountID');
    localStorage.removeItem('callingId');
    localStorage.removeItem('countryId');
    localStorage.removeItem('callingName');
    localStorage.removeItem('callingStake');
    localStorage.removeItem('dataurl');
    localStorage.removeItem('show_master_plan');
    clear();
  }
}
